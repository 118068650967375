.steps-content {
    margin-top: 10px;
    border-radius: 6px;
    min-height: 200px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 60px;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
}

.steps-action {
    margin-top: 24px;
    text-align: center;
    /*margin-bottom: 24px;*/
}

.steps-header {
    /*width: 100%;*/
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: white;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: none;
}