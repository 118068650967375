body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #0059b3 !important;
  border-color: #0059b3;
}

.ant-btn.ant-btn-primary {
  background: #0059b3 !important;
  border-color: #0059b3 !important;
  color: white !important;
  display: inline-flex;
  align-items: center;
}

.info-box {
  background: #0059b314 !important;
  border-color: #0059b3 !important;
  padding: 20px;
  color: #0059b3 !important;
}

.error-box {
  color: #d9364f !important;
  border-color: #d9364f !important;
  padding: 20px;
  background: #d9364f14 !important;
}

.success-box {
  color: #008758 !important;
  border-color: #008758 !important;
  background: #00875814 !important;
  padding: 20px;
}

.info-box .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0059b3;
  border-color: #0059b3!important;
}


.info-box .ant-checkbox .ant-checkbox-inner {

  border-color: #0059b3!important;
}




.info-box  .ant-radio-wrapper .pec-item{

  color: #0059b3 !important;
  font-weight: 700;
}


.info-box  .ant-radio-wrapper  .ant-radio-inner::after {
  border-color: #0059b3 !important;
}

.info-box  .ant-radio-wrapper  .ant-radio-inner {
  border-color: #0059b3 !important;
}


.success-box .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #008758;
  border-color: #008758!important;
}


.success-box .ant-checkbox .ant-checkbox-inner {

  border-color: #008758!important;
}

.error-box  .ant-radio-wrapper  .ant-radio-inner {
  border-color: #d9364f !important;
}


.error-box  .ant-radio-wrapper .pec-item{

  color: #d9364f !important;
  font-weight: 700;
}


.error-box  .ant-radio-wrapper  .ant-radio-inner::after {
  border-color: #d9364f !important;
}



.success-box  .ant-radio-wrapper .ant-radio-inner {

  border-color: #008758!important;
}


.success-box  .ant-radio-wrapper .pec-item{

  color: #008758!important;
  font-weight: 700;
}


.success-box  .ant-radio-wrapper-checked .ant-radio-inner {

  border-color: #008758!important;

}

.ant-checkbox-wrapper {
  font-size: 1.1rem !important;
}

.success-box  .ant-radio-wrapper-checked .ant-radio-inner::after {

  background-color: #008758!important;

}

.success-box  .ant-radio .ant-radio-inner {

  border-color: #008758!important;
}



.error-box  .ant-radio .ant-radio-inner {
  border-color: #d9364f !important;
}

.ant-modal-header {
  background-color: #0059b3 !important;
}
.ant-modal-title {
  color: white !important;
}

.ant-modal-content {
  width: 100%;
}

.gov-buttons {
  display: inline-flex;
  align-items: center;
}

.gov-buttons span {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0px;
}


.error-msg {
  color: #d9364f !important;
  background-color: transparent !important;
}

.ant-btn.ant-btn-dashed.search {
  border-color: #0059b3 !important;
  color: #0059b3 !important;
  background-color: transparent;
}

.gov .ant-table-thead .ant-table-cell {
  font-weight:700;
}